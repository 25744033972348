EG.Models = EG.Models || {};

EG.Models.GlobalContext = Backbone.Model.extend({

    initialize: function () {
        // Expose Arrangees as a Collection and keep the two in sync.
        this.arrangees = new EG.Models.ArrangeeCollection(this.get('arrangees') || []);
        this.arrangees.on('update reset', function() {
            this.set('arrangees', this.arrangees.toJSON());

            // identify user ids that are considered travelers for prop11/eVar11
            var travelers = EG.user.userId?.toString();
            if (!this.arrangees.isEmpty()) {
                travelers = this.arrangees.map(function(a) {
                    return a.get('userId');
                }).join(',');
            }

            uitk.analytics.set({prop11: travelers, eVar11: travelers});
        }, this); // Will cause 'change' in GlobalContext, which then saves

        // Create/update cookies with changes from the server
        this.save();

        // Ensure cookies stay updated
        this.on('change', this.save);

        // if the user is an agent, update the header when global context changes
        if (EG && EG.agent && EG.agent.agentCode) {
            this.on('change', this.updateHeader);
        }
    },

    cookieName: 'egencia_globalcontext',
    cookieLegacyName: 'legacy_globalcontext',
    // Updates cookies. This is called on init and on every change, so it never needs to be called by a web app.
    save: function() {
        uitk.createCookie(this.cookieName, JSON.stringify(this));
        uitk.createCookie(this.cookieLegacyName, JSON.stringify(this.getLegacyData()));
    },

    /**
     * Function to replace the header when it turns stale
     * TODO: use async/await on this function when the transpilation isn't massive
     * @param {Object} param0 object that represents the changed state of the global context
     * @param {Number} [param0.changed.managedCompanyId] number that represents the changed state of the `managedCompanyId` attribute
     * @param {EG.Models.Arrangee[]} [param0.changed.arrangees] list of arrangees that represents the changed state of the `arrangees` attribute
     */
    updateHeader: function({changed: {managedCompanyId, arrangees}}) {
        // only call GHS when managed company or arrangees change
        if (managedCompanyId === undefined && arrangees === undefined) { return; }
        $.getJSON('/global-header-service/v1/menu-items').then(function(headerFooterModel) {
            // render html with hbs runtime
            const headerHtmlString = Handlebars.templates['partials/uitk/header'](headerFooterModel);

            // create element to hold the updated node
            const wrappedUpdatedHeader = document.createElement('div');
            wrappedUpdatedHeader.innerHTML = headerHtmlString.trim();

            // get element from dom and replace it with an updated version
            const staleHeader = document.getElementById('global-header');
            staleHeader.parentNode.replaceChild(wrappedUpdatedHeader.firstChild, staleHeader);
        });
    },

    destroy: function() {
        uitk.deleteCookie(this.cookieName);
        uitk.deleteCookie(this.cookieLegacyName);
    },

    // Legacy can only take a subset of GlobalContext, so we maintain this other stripped-down data set to share with them
    getLegacyData: function() {
        // Picks a whitelist for legacy
        var data = this.pick(['managedCompanyId', 'tripId', 'tripItemId', 'requisitionId', 'requisitionSource', 'meetingId', 'arrangees']);

        // Overwrites with only those Arrangee attributes that are supported by legacy
        if (data.arrangees && data.arrangees.length > 0) {
            data.arrangees = this.arrangees.map(function (arrangee) {
                var data = {};
                if (arrangee.get('isGuest')) {
                    data = arrangee.pick(['email', 'isGuest', 'title', 'firstName', 'lastName']); // Guest attrs only for legacy
                }
                data.userId = arrangee.id;
                return data;
            });
        }

        return data;
    }

});
