EG.Models = EG.Models || {};

EG.Models.Cdd = Backbone.Model.extend({
    defaults: {
        'display_name': '', // Required
        'geo_location': [], // Required, [latitude, longitude]
        'address': '', // Optional
        'radius': {
            'value': null, // Required
            'unit': '' // Required
        },
        'locale': '' // Optional
    }
});

EG.Models.CddCollection = Backbone.Collection.extend({
    model: EG.Models.Cdd,

    initialize: function () {
        // Init with bootstrapped data if it exists
        if (EG.bootstrapped.cdds) {
            this.reset(EG.bootstrapped.cdds, {silent: true});
        }
    },

    url: '/location-service/v1/locations/custom',

    lob: 'HOTEL', // Default line of business

    parse: function (response) {
        return response.locations;
    },

    // Returns matching CDDs in Autocomplete format
    getAutocompleteResults: function (query, opts) {
        var parts = query.trim().split(' ').filter(function (part) { return part; });

        if (query) {
            return this.chain()
                .filter(byQuery)
                .sortBy(beginsWithQuery)
                .map(toAutocompleteFormat)
                .first(opts.max)
                .value();
        }

        return undefined;

        function byQuery(model) {
            var matchValue = true;
            var filterCriteria = model.get('display_name') + ' ' + model.get('address'); // build search criteria data
            var queryCriteria;

            for (var i = 0; parts.length > i; i++) {
                queryCriteria = new RegExp(uitk.utils.escapeSpecialRegexChars(parts[i]), 'ig');
                matchValue = matchValue && filterCriteria.match(queryCriteria) !== null; // Does it contain a part of the query?
                filterCriteria = matchValue ? filterCriteria.replace(queryCriteria, '') : filterCriteria; // clean up the filter
            }

            filterCriteria = undefined; // clear out criteria data after looking for a match
            queryCriteria = undefined;

            return matchValue;
        }

        function beginsWithQuery(model) {
            // sorts from lower to higher. if there is a match, then it will put the result higher up in the list
            //  matching criteria is ^query (if the model's display_name starts with the query)
            return model.get('display_name').match(new RegExp('^' + uitk.utils.escapeSpecialRegexChars(query), 'ig')) !== null ? 0 : 1;
        }

        function toAutocompleteFormat(model) {
            var displayVal = model.get('display_name');
            var optDisplayVal = model.get('address');

            for (var i = 0; parts.length > i; i++) {
                // passing in a function so the casing is respected and not changed. (Check MDN String.prototype.replace)
                displayVal = displayVal.replace(new RegExp(uitk.utils.escapeSpecialRegexChars(parts[i]) + '(?!>)', 'ig'), function(match) {
                    return '<b>' + match + '</b>';
                });

                optDisplayVal = optDisplayVal.replace(new RegExp(uitk.utils.escapeSpecialRegexChars(parts[i]) + '(?!>)', 'ig'), function(match) {
                    return '<b>' + match + '</b>';
                });
            }

            return {id: model.get('id'), result: JSON.stringify(model), value: model.get('display_name'),
                    display_value: displayVal, category: 'CUSTOM_DEST', opt_display_value: optDisplayVal};
        }
    }

});

