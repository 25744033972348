/* This presenter does the few things that all Egencia web apps need. */
(function () {
    'use strict';

    //----- Init -----
    const $delegate = $('body');
    uitk.init(); // Required to use UITK API and components
    EG.globalContext = new EG.Models.GlobalContext(EG.globalContext);

    //----- User events -----
    $delegate.on('change', '#locale-chooser', setLocale);
    $delegate.on('click', '#primary-nav-feedback', showFeedbackMenu);
    $delegate.on('click', '#log-out', logOut);
    uitk.subscribe('modal.confirm', redirectToOutdatedbrowser);
    
    // Adds focus indicator classes
    function enableFocusModeMouse(){
        document.documentElement.classList.add("ege-no-outline");
        document.documentElement.removeEventListener("click", enableFocusModeMouse);
        document.documentElement.addEventListener("keydown", enabledFocusModeKeyboard);
    }
    
    function enabledFocusModeKeyboard(){
        document.documentElement.classList.remove("ege-no-outline");
        document.documentElement.removeEventListener("keydown", enabledFocusModeKeyboard);
        document.documentElement.addEventListener("mousedown", enableFocusModeMouse);
    }
    enableFocusModeMouse();

    //----- Document ready -----
    $(document).ready(function() {
        showUnsupportedBrowserWarning();
    }).on('ajaxError', function(event, xhr) {
        if (!xhr) {
            return;
        }

        switch (xhr.status) {
            case 401:
                uitk.publish('auth.notauthorized', xhr, event);
                break;
            default:
                break;
        }
    });

    //----- Private functions -----
    function setLocale() {
        uitk.createCookie('egencia_locale', this.value);
        uitk.analytics.track('a', {
            linkId: "header.language.selector"
        });
        location.reload(true);
    }

    function showFeedbackMenu(e) {
        // Prevent following the href
        e.preventDefault();

        const feedbackContent = `<iframe class="feedback-content" src=${uitk.getFeedbackSurveyUrl()} title="Feedback Panel"></iframe>`
        const feedbackWidget = uitk.modal.create({
            modalId: 'feedback-widget',
            content: feedbackContent,
            title: ' ',
            panel: true,
            panelSize: "small"
        })

        feedbackWidget.open();
    }

    function logOut() {
        EG.globalContext.destroy();
        sessionStorage.removeItem('chatBot.enabled');
        sessionStorage.removeItem('sfChatBot.enabled');
        uitk.sfChatBot.clearSfChat();
    }

    let skipLink = document.getElementById('skip-link');
    if(skipLink) {
        skipLink.addEventListener('click', function(e) {
            e.stopPropagation();
            document.getElementById("maincontent").focus();
        });
    }

    function showUnsupportedBrowserWarning() {
        if($('html').hasClass('lt-ie11')) {
            uitk.modal.create({
                modalId: 'unsupported-browser-warning',
                confirm: true,
                content: `
                    <h3 class="modal-title">${uitk.i18n.msg('uitk_unsupported_browser_warning_modal_title')}</h3>
                    <p>${uitk.i18n.msg('uitk_unsupported_browser_warning_modal_body')}</p>`,
                confirmText: 'uitk_unsupported_browser_warning_modal_confirmText',
                cancelText:  'uitk_unsupported_browser_warning_modal_closeText',
                optOut: true
            }, true);
        }
    }

    function redirectToOutdatedbrowser(topic, data) {
        if(data.id === 'unsupported-browser-warning') {
            window.open('http://outdatedbrowser.com', '_blank');
        }
    }
})();
